<template>
    <div class="session-bg">
        <div class="signup4-container">
            <base-card>
                <v-row no-gutters>
                    <v-col cols="12" md="6" class="pa-10">
                        <img
                            class=""
                            src="@/assets/images/pluviometre/logo-MAEP.png"
                        />
                    </v-col>
                    <v-col cols="12" md="6" class="bg-gray-300">
                        <div class="pa-4 mt-3">
                            <v-text-field v-model="email"  outlined label="E-mail" />
                            <v-text-field
                                v-model="password"
                                outlined
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show ? 'text' : 'password'"
                                name="input-10-2"
                                label="Password"
                                @click:append="show = !show"
                            ></v-text-field>
                           

                            <div class="d-flex align-center">
                                <v-btn depressed color="primary" @click="login">Connexion</v-btn>
                                
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </base-card>
        </div>
    </div>
</template>
<script>
import api from '@/serviceApi/apiService'

export default {
    name: 'login',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Login'
    },
    data() {
        return {
            show: false,
            password: 'Password',
            checkbox1: true,
            checkbox2: false,
            email: '',
            password: '',
        }
    },
    methods: {
        async login() {
            let fd = new FormData();
            fd.append('email', this.email);
            fd.append('password', this.password);
            const data =await api.createUpdatedata('admin-backoffice/compte-admins-login',fd)
            if(data.status == true){
                 localStorage.setItem('token_baro', data.token_baro)
                //this.showAlert(data.message, 'success')
                this.$router.push('/dashboard')
            }else{
                this.showAlert(data.message, 'error')
            }
        },
        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },
    }
}
</script>
<style lang="scss">
.session-bg {
    background-image: url('../../../assets/images/pluviometre/screen.jpeg');
    height: 100vh;
    align-items: center;
    place-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
}
.signup4-header img {
    width: 200px;
}
.signup4-container img {
    max-height: 300px;
    max-width: 100%;
}
@media only screen and (min-width: 1024px) {
    .signup4-container {
        max-width: 800px;
    }
}
</style>
